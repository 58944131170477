import { render, staticRenderFns } from "./group_access.vue?vue&type=template&id=07944b47&scoped=true"
import script from "./group_access.vue?vue&type=script&lang=js"
export * from "./group_access.vue?vue&type=script&lang=js"
import style0 from "./group_access.vue?vue&type=style&index=0&id=07944b47&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07944b47",
  null
  
)

export default component.exports