<template>
  <div>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <br />
    <div v-if="!loading">
      <div v-if="items.length">
        <b-table
          id="atTable"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          hover
          responsive
          style="min-height: 200px"
        >
          <template v-slot:head(actions)>
            <div
              class="d-flex justify-content-center"
              style="white-space: nowrap; overflow: hidden"
            >
              <span style="text-overflow: ellipsis">Actions</span>
            </div>
          </template>
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
          <template #cell(scan_status)="data">
            <b-badge variant="primary" v-if="data.item.scan_status == 0"
              >Scheduled</b-badge
            >
            <b-badge variant="warning" v-else-if="data.item.scan_status == 1"
              >In Progress</b-badge
            >
            <b-badge variant="success" v-else-if="data.item.scan_status == 2"
              >Completed</b-badge
            >
            <b-badge variant="danger" v-else>Failed</b-badge>
          </template>
          <template #cell(actions)="data">
            <div
              class="d-flex justify-content-center"
              
            >
              <b-dropdown
                size="sm"
                class="ml-1"
                variant="outline-primary"
                text="Actions"
              >
                <b-dropdown-item
                  v-if="
                    data.item.scan_status == 0 ||
                    data.item.scan_status == 1 ||
                    data.item.scan_status == 2
                  "
                  @click="refresh(data.item.scan_id)"
                  >Refresh</b-dropdown-item
                >
                <b-dropdown-item
                  @click="gotoDelete(data.item.scan_id, data.item.domain)"
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
              <b-button
                class="ml-1"
                v-if="data.item.scan_status == 2"
                @click="getSummary(data.item.scan_id)"
                variant="primary"
                size="sm"
              >
                Scan Summary
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="SlashIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </div>
    </div>

    <b-pagination-nav
      v-model="currentPage"
      :numberOfPages="total"
      :total-rows="rows"
      :link-gen="linkGen"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="atTable"
      use-router
    />
    <!-- Right Sidebar starts -->
    <b-sidebar
      id="sidebar-asset-handler"
      no-close-on-backdrop="true"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="50%"
    >
      <!-- <AssetsDiscover :scan_id="scan_id" :closeSidebar="closeSidebar" /> -->
    </b-sidebar>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
    >
      <b-card-text> Do you really want to delete {{ domain }} ? </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteScan()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
    <!-- </b-card-code> -->
    <!-- </b-tab> -->
    <!-- </b-tabs> -->
  </div>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BBadge,
  BSidebar,
  BFormGroup,
  VBToggle,
  BTabs,
  BTab,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import AssetsDiscover from "../AssetDiscovery/components/assets_discovered.vue";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    VBToggle,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    BBadge,
    // AssetsDiscover,
    BSidebar,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    vSelect,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      // Call orgaization API and return in this format
      scan_id: 0,
      selected_group: 0,
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "agenttype.agent_type_nicename", label: "Type" },
        { key: "scan_status", label: "Status" },
        { key: "errors", label: "Errors" },
        { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,

      domain_organization: null,
      organizations: [],
      name_filter: "",
      domain: "",
      loading: false,
      schedule_selected: null,
      schedule_data: [
        { value: null, text: "--Select Schedule--" },
        { value: 5, text: "Once in a month" },
        { value: 6, text: "Once in a three-month" },
        { value: 7, text: "Once in a six-month" },
        { value: 8, text: "Once in a year" },
      ],
      openDeleteModal: false,
      group_scan_id: "",
      domain: "",
      agent_id: null,
      tabIndex_data: this.tabIndex,
    };
  },
  props: {
    agent_types: {
      type: Array,
      required: true,
    },
    group_id: {
      type: Number,
      required: true,
    },
    // DomainTabIndex: {
    //   type: Number,
    //   required: true,
    // },
    // tabIndex: {
    //   type: Number,
    //   required: true,
    // },
  },
  watch: {
    // agent_types: {
    //   handler(newAgentTypes, oldAgentTypes) {
    //     if (newAgentTypes.length > 0) {
    //       this.load();
    //     }
    //   },
    //   immediate: true,
    // },
    // DomainTabIndex: {
    //   handler(newTabIndex, oldTabIndex) {
    //     if (newTabIndex === 0) {
    //       this.load();
    //     }
    //   },
    //   immediate: true,
    // },
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
  },
  created: function () {
    // if (this.DomainTabIndex === 0) {
    this.load();
    // }
  },

  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY hh:mm A");
      }
    },
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    loadOrg: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      this.searchFn();
    },

    load: function () {
      this.loading = true;

      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/group-scan/?group_id=" +
          this.group_id,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.loading = false;
      });
    },
    gotoDelete(scan_id, domain) {
      this.openDeleteModal = true;
      this.group_scan_id = scan_id;
      this.domain = domain;
    },
    deleteScan: function () {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/group-scan/" +
          this.group_scan_id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data == "") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully deleted",
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.openDeleteModal = false;
          this.searchFn();
        }
      });
    },

    searchFn: function (reset = true) {
      this.is_search_on = false;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "scan/group-scan/?group_id=" +
        this.group_id +
        "&page=" +
        this.currentPage;
      //   if (this.name_filter != null && this.name_filter != "") {
      //     this.filter.push({ name: this.name_filter });
      //     url = url + "&name=" + this.name_filter;
      //   }
      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },

    refresh: function (id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/group-scan/" +
          id +
          "/scan-refresh/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.searchFn();
      });
    },
    getSummary(id) {
      this.$router.push({
        name: "Scan Summary",
        params: { id: id },
        query: {
          groupScan: true,
        },
      });
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #04002f !important;
  color: #fff;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
