  <template>
  <b-card-code :title="sidebar_title">
    <div class="d-flex justify-content-center mb-0">
      <b-form-radio-group v-model="selectedOption">
        <b-form-radio value="option1">Existing Assets</b-form-radio>
        <b-form-radio value="option2">Add new Asset</b-form-radio>
      </b-form-radio-group>
    </div>
    <b-card no-body no-header v-if="selectedOption === 'option1'">
      <validation-observer ref="GroupAssetsAddForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="addAssets">
          <!-- Organization-->
          <b-form-group label="Add Existing Assets" label-for="Assets">
            <template #label>
              <span>Add Existing Assets</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="assets"
              rules="required"
            >
              <v-select
                v-model="assets"
                multiple
                label="text"
                :options="asset_options"
                placeholder="--Search Asset--"
                @search="fetchOptions"
                autocomplete
                state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            type="submit"
            variant="primary"
            size="sm"
            :disabled="invalid"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>Add to group</span>
          </b-button>
        </b-form>
      </validation-observer>

      <div class="row">
        <div class="col"><hr /></div>
        <div class="col-auto">OR</div>
        <div class="col"><hr /></div>
      </div>
      <h4 class="text-primary mb-1">Upload Asset</h4>
      <div class="mb-2">
        <h6>
          Sample file :
          <a href="files/sample_asset_upload_file.xlsx" download>
            Click here to download sample file.
          </a>
        </h6>
      </div>
      <!-- Asset Group -->
      <!-- <b-form-group label="Asset Exposure">
        <validation-provider
          #default="{ errors }"
          name="asset_group_type"
          rules="required"
        >
          <b-form-select
            v-model="asset_group_type"
            :options="assetGroupTypes"
            :state="errors.length > 0 ? false : null"
          ></b-form-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group v-if="asset_group_type === 'internal'">
        <template #label>
          <span>Network Location</span>
          <b-button
            class="ml-1"
            style="padding: 0.186rem 1rem !important"
            @click="gotoCreateNetwork()"
            variant="outline-success"
            size="sm"
            >+ Add Network Location</b-button
          >
        </template>
        <v-select
          v-model="networkLocation"
          label="text"
          placeholder="--Select Network Location--"
          :options="networkLocationOptions"
          autocomplete
          :reduce="(networkLocation) => networkLocation.value"
          :clearable="false"
        />
      </b-form-group> -->
      <!-- Upload form -->
      <validation-observer ref="AssetUploadForm" >
        <b-form class="auth-login-form mt-2" @submit.prevent="uploadAsset">
          <!-- Asset Type-->
          <!-- <b-form-group label="Asset Type" label-for="Asset Type">
            <validation-provider
              #default="{ errors }"
              name="asset_type"
              rules="required"
            >
              <b-form-select
                v-model="asset_type"
                :options="assetTypes"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group> -->
          <!--Upload File -->
          <b-form-group label="Upload File" label-for="File">
            <template #label>
              <span>Upload File</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <b-form-file
              v-model="asset_file"
              :state="Boolean(asset_file)"
              ref="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-on:change="handleFileUpload()"
            ></b-form-file>
            <div class="mt-3">
              Selected file: {{ asset_file ? asset_file.name : "" }}
            </div>
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            size="sm"
            :disabled="asset_file === null"
          >
            Upload
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card no-body no-header v-if="selectedOption === 'option2'">
      <AddAssetOption
        :group_id="group_id"
        :closeSidebar="closeSidebar"
        :assetRedirct="assetRedirct"
      />
    </b-card>

    <b-modal
      ref="modal-new-network-location"
      title="Create New Network Location"
      hide-footer
      size="lg"
    >
      <AddNetworkLocation
        :redirectNetwork="redirectNetwork"
        :closeSidebar="closeNetworkModal"
      />
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { integer } from "vee-validate/dist/rules";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddAssetOption from "./componets/addAssetOption.vue";
import AddNetworkLocation from "../NetworkLocation/add.vue";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    BSpinner,
    BFormRadio,
    BFormRadioGroup,
    AddAssetOption,
    AddNetworkLocation,
  },
  props: {
    org_id: {
      type: Number,
      required: true,
    },
    group_id: {
      type: Number,
      required: true,
    },
    group_owner_id: {
      type: Number,
      required: true,
    },
    sidebar_title: {
      type: String,
      required: true,
    },
    assetTypes: {
      type: Array,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
  },
  watch: {
    // sidebar_title() {
    //   this.fetchOptions();
    // },
  },
  // mounted() {
  //   this.fetchOptions();
  // },
  data() {
    return {
      assets: [],
      asset_options: [],
      page_title: "Add Assets",
      asset_file: null,
      asset_type: "",
      isloading: false,
      asset_group_type: null,
      assetGroupTypes: [
        { value: null, text: "--Select Asset Exposure--" },
        { value: "internal", text: "Internal" },
        { value: "external", text: "Internet Facing" },
      ],
      selectedOption: "option1",
      // group_owner_id: this.props.group_owner_id
      networkLocationOptions: [],
      networkLocation: null,
      redirectNetwork: true,
      assetRedirct: false,
    };
  },
  watch: {
    asset_group_type: function (newAssetGroupType, oldAssetGroupType) {
      if (newAssetGroupType === "internal") {
        this.loadnetworkLocation();
      }
    },
  },
  methods: {
    addAssets: function () {
      this.$refs.GroupAssetsAddForm.validate().then((success) => {
        this.isloading = true;
        let data = {
          group_id: this.$route.params.id,
          assets: this.assets,
        };
        const a_options = {
          method: "POST",
          data: data,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "asset/group/add-assets/",
        };
        var self = this;
        this.$http(a_options).then((res) => {
          if (res.data.status == "success") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Added Asset Successfully",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.isloading = false;
            this.assets = [];
            this.closeSidebar();
          }
        });
      });
    },

    //----------- fetch data

    fetchOptions(search) {
      if (search.length > 2) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset?org_id=" +
            this.org_id +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.asset_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].asset_name +
                ")",
            };
            self.asset_options.push(a);
          });
        });
      }
    },
    // --------------previous asset list with search

    // fetchOptions(search, loading) {
    //   if (search.length) {
    //     loading(true);
    //     // Load assets here
    //     const a_options = {
    //       method: "GET",
    //       headers: { "content-type": "application/json" },
    //       url:
    //         process.env.VUE_APP_BASEURL +
    //         "asset/org-asset?org_id=" +
    //         this.org_id +
    //         "&name=" +
    //         search,
    //     };
    //     var self = this;
    //     this.$http(a_options).then((res) => {
    //       self.asset_options = [];
    //       res.data.results.map(function (value, key) {
    //         let a = {
    //           value: res.data.results[key].asset_id,
    //           text:
    //             res.data.results[key].asset_name +
    //             " (" +
    //             res.data.results[key].assetType.asset_type_name +
    //             ")",
    //         };
    //         self.asset_options.push(a);
    //         loading(false);
    //       });
    //     });
    //   }
    // },
    uploadAsset: function () {
      this.$refs.AssetUploadForm.validate().then((success) => {
        if (success) {
          //call to axios
          console.log("success");

          let formData = new FormData();
          formData.append("file", this.asset_file);
          formData.append("asset_owner_id", this.group_owner_id);
          // formData.append("asset_type", this.asset_type);
          formData.append("asset_group", this.group_id);
          // formData.append("asset_grouptype", this.asset_group_type);
          // if (this.asset_group_type == "internal") {
          //   formData.append("network_location", this.networkLocation);
          // }
          const options = {
            method: "POST",
            headers: { "content-type": "multipart/form-data" },
            data: formData,
            url: process.env.VUE_APP_BASEURL + "asset/org-asset/upload/",
          };
          var self = this;
          this.$http(options).then((res) => {
            console.log(res);
            var toast_title =
              "Successfully queued the request. Check back later if there are many to add!";
            var toast_variant = "success";
            if (res.data.status == "success") {
              //   this.$router.push({ name: "Assets" });
              this.closeSidebar();
            } else {
              toast_title = res.data.message;
              toast_variant = "danger";
            }
            self.$toast({
              component: ToastificationContent,
              props: {
                title: toast_title,
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: toast_variant,
              },
            });
          });
        }
      });
    },
    handleFileUpload() {
      this.asset_file = this.$refs.file.files[0];
    },
    loadnetworkLocation() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/network-location/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.networkLocationOptions = res.data.map((item) => ({
          value: item.id,
          text: item.title,
        }));

        // Find the index of the item with text "DEFAULT"
        const defaultIndex = this.networkLocationOptions.findIndex(
          (item) => item.text === "DEFAULT"
        );

        // If "DEFAULT" is found, set it as the default selection
        if (defaultIndex !== -1) {
          this.networkLocation =
            this.networkLocationOptions[defaultIndex].value;
        } else if (this.networkLocationOptions.length > 0) {
          // If "DEFAULT" is not found, fallback to selecting the first item
          this.networkLocation = this.networkLocationOptions[0].value;
        }
      });
    },
    gotoCreateNetwork() {
      this.$refs["modal-new-network-location"].show();
    },
    closeNetworkModal() {
      this.$refs["modal-new-network-location"].hide();
      this.loadnetworkLocation();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
