<template>
  <div class="row">
    <div class="col-12 d-flex">
      <!-- <b-form-select
        class="ml-1"
        style="width: 20%"
        v-model="asset_group_id"
        :options="group_filter_options"
        disabled
        size="sm"
      /> -->
      <div class="clearfix ml-auto">
        <b-dropdown
          variant="outline-secondary"
          text="More Columns"
          checkbox-menu
          allow-focus
          class="mr-1"
          size="sm"
        >
          <b-dropdown-form>
            <b-form-checkbox
              style="margin-top: 2px; margin-bottom: 2px"
              :disabled="visibleFields.length == 1 && field.visible"
              v-for="field in fields.slice(2)"
              :key="field.key"
              v-model="field.visible"
              inline
            >
              {{ field.label }}
            </b-form-checkbox>
          </b-dropdown-form>
        </b-dropdown>
      </div>
    </div>
    <div class="col-12 mt-1">
      <!-- {{items}} -->
      <b-table
        responsive
        id="vulnTable"
        :fields="visibleFields"
        :items="items"
        :per-page="perPage"
        v-if="!loading"
        hover
      >
        <template v-slot:head(cve_meta_data)>
          <div class="d-flex justify-content-between">
            <span>Remediation Priority</span>
            <div class="d-flex flex-column">
              <feather-icon
                icon="ChevronUpIcon"
                class="mr-50"
                @click="setSort(true)"
                style="height: 10px"
              />
              <feather-icon
                icon="ChevronDownIcon"
                class="mr-50"
                @click="setSort(false)"
                style="height: 10px"
              />
            </div>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>

        <!-- <template #cell(selected_vulns)="data">
        <b-form-checkbox
          name="selected-items"
          v-model="selectedVulns"
          :value="data.item.id"
          @change="updateCheckAll()"
        >
        </b-form-checkbox>
      </template> -->

        <template #cell(name)="data">
          <a class="text-primary" @click="showSidebar(data)">
            <del v-if="data.item.status == 1">{{ data.item.name }}</del>
            <span v-else>{{ data.item.name }}</span>
          </a>
        </template>
        <template #cell(cve_meta_data)="data">
          <b-badge
            style="background-color: #991d28"
            v-if="data.item.cve_meta_data == 1"
            >Critical</b-badge
          >
          <b-badge variant="danger" v-if="data.item.cve_meta_data == 2"
            >High</b-badge
          >
          <b-badge variant="warning" v-if="data.item.cve_meta_data == 3"
            >Medium</b-badge
          >
          <b-badge variant="success" v-if="data.item.cve_meta_data == 4"
            >Low</b-badge
          >
          <b-badge variant="info" v-if="data.item.cve_meta_data == 5"
            >Very Low</b-badge
          >
          <b-badge variant="light-danger" v-if="data.item.cve_meta_data == null"
            >NA</b-badge
          >
          <div class="mt-1">
            <span class="d-flex" style="front-size: 2px">
              <span class="mr-1" style="font-size: 10px">CISA KEV:</span>
              <b-badge
                variant="success"
                style="font-size: 8px"
                v-if="data.item.cisa_kev == true"
                >Yes</b-badge
              >
              <b-badge
                variant="danger"
                v-if="data.item.cisa_kev == false"
                style="font-size: 8px"
                >No</b-badge
              >
            </span>
            <span class="d-flex">
              <span style="font-size: 10px" class="mr-1">EPSS:</span>
              <span style="font-size: 10px">{{ data.item.epss }}</span>
            </span>
          </div>
        </template>

        <template #cell(created_at)="data">
          <div
            class="d-flex justify-content-between"
            style="white-space: nowrap; overflow: hidden"
          >
            <span style="text-overflow: ellipsis" class="text-capitalize">{{
              data.item.created_at | formatdate
            }}</span>
          </div>
        </template>
        <!-- #cell(asset)="data" -->
        <template v-slot:cell(asset)="data">
          <router-link :to="'/assets/scan/' + data.item.asset.asset_id">{{
            data.item.asset.asset_name
          }}</router-link>
        </template>

        <template #cell(is_verified)="data">
          <b-avatar v-if="data.item.is_verified" variant="success">
            <feather-icon icon="ThumbsUpIcon" />
          </b-avatar>
          <b-avatar v-else variant="danger">
            <feather-icon icon="ThumbsDownIcon" />
          </b-avatar>
        </template>
        <!-- #cell(severity)="data" -->
        <template v-slot:cell(severity)="data">
          <b-badge
            style="background-color: #991d28"
            v-if="data.item.severity == 'critical'"
            >Critical</b-badge
          >
          <b-badge variant="danger" v-else-if="data.item.severity == 'high'"
            >High</b-badge
          >
          <b-badge variant="warning" v-else-if="data.item.severity == 'medium'"
            >Medium</b-badge
          >
          <b-badge variant="primary" v-else-if="data.item.severity == 'low'"
            >Low</b-badge
          >
          <b-badge variant="success" v-else-if="data.item.severity == 'info'"
            >Info</b-badge
          >
          <b-badge variant="secondary" v-else>Unknown</b-badge>
        </template>
        <!-- #cell(status)="data" -->
        <template v-slot:cell(status)="data">
          <b-badge variant="danger" v-if="data.item.status == 0">Open</b-badge>
          <b-badge variant="success" v-if="data.item.status == 1"
            >Closed</b-badge
          >
          <b-badge variant="warning" v-if="data.item.status == 2"
            >False Positive</b-badge
          >
          <b-badge variant="success" v-if="data.item.status == 3"
            >Accepted</b-badge
          >
          <b-badge variant="warning" v-if="data.item.status == 4"
            >Assigned</b-badge
          >
          <b-badge variant="warning" v-if="data.item.status == 5"
            >Mitigated</b-badge
          >
        </template>
        <!-- #cell(is_assigned)="data" -->
        <template v-slot:cell(is_assigned)="data">
          <b-badge variant="success" v-if="data.item.is_assigned == true"
            >Yes</b-badge
          >
          <b-badge variant="danger" v-else>No</b-badge>
        </template>
        <template v-slot:cell(protocol)="data">
          <b-badge variant="primary" v-if="data.item.protocol == 'tcp'"
            >TCP</b-badge
          >
          <b-badge variant="info" v-if="data.item.protocol == 'udp'"
            >UDP</b-badge
          >
        </template>
        <template #cell(updated_at)="data">
          <div
            class="d-flex justify-content-between"
            style="white-space: nowrap; overflow: hidden"
          >
            <span style="text-overflow: ellipsis" class="text-capitalize">{{
              data.item.updated_at | formatdate
            }}</span>
          </div>
        </template>
        <!-- <template #cell(actions)="data">
        <button
          @click="showSidebar(data)"
          type="button"
          class="btn btn-primary btn-sm mr-1"
          style="position: relative"
        >
          Details
        </button>
      </template> -->
      </b-table>

      <b-pagination-nav
        v-model="currentPage"
        :number-of-pages="total"
        :link-gen="linkGen"
        :total-rows="rows"
        :per-page="perPage"
        :align="pagination_pos"
        aria-controls="vulnTable"
        use-router
        v-if="!loading"
      />
    </div>
    <b-sidebar
      id="sidebar-vuln-handler"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <!-- Header -->
      <VulnDetail
        :vuln="vuln"
        :callBack="searchFn"
        :closeSidebar="closeSidebar"
        :asset_id="asset_id"
        :organization="organization_id"
        @div-id="handleDivId"
        :activeTab="activeTab"
      />
      <!-- <div id="myDiv">
        <h1>hello</h1>
      </div> -->
    </b-sidebar>
  </div>
</template>
<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BButton,
  BSidebar,
  VBToggle,
  BBadge,
  BFormInput,
  BRow,
  BFormSelect,
  BJumbotron,
  BFormGroup,
  BForm,
  BFormCheckbox,
  BAvatar,
  BDropdown,
  BDropdownForm,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";
import VulnDetail from "../../Asset/components/vuln_details.vue";
// import TaskForm from "./components/mitigate_bulk.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BSpinner,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BSidebar,
    VBToggle,

    BBadge,
    BForm,
    BFormInput,
    BFormCheckbox,
    BRow,
    BFormSelect,
    BJumbotron,
    BFormGroup,
    vSelect,

    BAvatar,
    BDropdown,
    BDropdownForm,
    BDropdownItem,
    flatPickr,
    VulnDetail,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      query: false,
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 20,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#", visible: true },
        // { key: "selected_vulns", label: "select", visible: true },
        { key: "name", label: "Name", sortable: true, visible: true },
        { key: "cve_meta_data", label: "Remediation Priority", visible: true },
        { key: "severity", label: "Severity", visible: true },
        { key: "status", label: "Status", visible: true },
        { key: "asset", label: "Asset", visible: true },
        { key: "port", label: "Port", visible: true },
        { key: "protocol", label: "Protocol", visible: false },
        { key: "is_verified", label: "Verified", visible: true },
        { key: "is_assigned", label: "Assigned", visible: true },
        { key: "created_at", label: "Added At", visible: false },
        { key: "cvss_score", label: "CVSS Score", visible: false },
        { key: "cve", label: "CVE", visible: false },
        { key: "path", label: "Path", visible: false },
        { key: "parameter", label: "Parameter", visible: false },
        { key: "updated_at", label: "Updated At", visible: false },
        { key: "source", label: "Source", visible: false },
        // { key: "actions", label: "Actions" },
      ],
      asset_id: 0,
      organization_id: 0,
      vuln: {},
      is_assigned: null,
      assigned_filter_options: [
        { value: null, text: "--Is Assigned?--" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],
      // severity_filter: null,
      severity_filter: [],
      severity_filter_options: [
        // { value: null, text: "--Select Severity--" },
        { value: "info", text: "Info" },
        { value: "low", text: "Low" },
        { value: "medium", text: "Medium" },
        { value: "high", text: "High" },
        { value: "critical", text: "Critical" },
      ],
      name_filter: "",
      asset_filter: null,
      asset_options: [],
      // status_filter: null,
      status_filter: [],
      status_filter_options: [
        // { value: null, text: "--Select Status--" },
        { value: 0, text: "Open" },
        { value: 1, text: "Closed" },
        { value: 2, text: "False Positive" },
        { value: 3, text: "Accepted" },
        // { value: 4, text: "Assigned" },
        { value: 5, text: "Mitigated" },
      ],
      // group_filter: null,
      // group_filter_options: [{ value: null, text: "--Select Group--" }],
      group_filter: [],
      group_filter_options: [],
      port_filter: "",
      port_type: null,
      port_type_options: [
        { value: null, text: "Protocol Type" },
        { value: "tcp", text: "TCP" },
        { value: "udp", text: "UDP" },
      ],
      selectedVulns: [],
      with_selected_options: [
        { value: null, text: "--Actions--" },
        // { value: "export_to_csv", text: "Export to CSV" },
        { value: "mitigate", text: "Mitigate" },
        { value: "mark_as_closed", text: "Mark as Closed" },
        { value: "mark_as_open", text: "Mark as Open" },
        { value: "mark_as_false_positive", text: "Mark as False Positive" },
        { value: "accept", text: "Accept the Risk" },
        { value: "delete", text: "Delete" },
        { value: "mark_as_verified", text: "Mark as verified" },
        { value: "mark_as_unverified", text: "Mark as unverified" },
        { value: "mark_as_exploited", text: "Mark as exploited" },
        { value: "mark_as_unexploited", text: "Mark as unexploited" },
      ],
      selected_sort_options: [
        { value: null, text: "--Sort With--" },
        { value: "updated_at", text: "Last Updated" },
        { value: "added_at", text: "Added At" },
      ],
      selected_sort: null,

      is_verified: null,
      verified_filter_options: [
        { value: null, text: "--Is Verified?--" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],
      is_exploited: null,
      exploited_filter_options: [
        { value: null, text: "--Is Exploited?--" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],

      selected_action: null,
      isCheckAllFilterResults: false,
      isCheckAll: false,
      is_staff: this.$store.state.app.user.is_staff,
      // organization: this.$store.state.app.org_id,
      // organization : this.$store.state.app.user.profile.organization,
      organization: null,
      organizations: [],
      users: [{ value: null, text: "--Select User--" }],
      filter: [],
      is_search_on: false,
      total_vulnerabilties_count: 0,
      loading: false,
      label_options: [],
      label_filter: [],
      multi_asset_filter: [],
      multi_asset_options: [],
      // rangeDate: null,
      fromDate: null,
      endDate: null,

      isloading: false,
      isloadingCsv: false,

      isHidden: false,
      isRemediation: false,
    };
  },
  props: {
    asset_group_id: {
      type: Number,
      required: true,
    },
    organization: {
      type: Number,
      required: true,
    },
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  created: function () {
    // if (this.org_id != null) {

    // }
    this.load();
    this.loadGroupsAndSearch();
    this.searchFn();
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);

      // this.isCheckAll = false
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  methods: {
    linkGen: function (pageNum) {
      let current_path = this.$router.currentRoute.path + "?page=" + pageNum;
      return current_path;
    },
    load: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      if (this.is_staff) {
        this.loading = true;
        this.organization = this.$store.state.app.org_id;
        // const o_options = {
        //   method: "GET",
        //   headers: { "content-type": "application/json" },
        //   url:
        //     process.env.VUE_APP_BASEURL +
        //     "organization/organization/get-all-org",
        // };
        // var self = this;
        // this.$http(o_options).then((res) => {
        //   res.data.map(function (value, key) {
        //     let org = {
        //       value: res.data[key].org_id,
        //       text: res.data[key].org_name,
        //     };
        //     self.organizations.push(org);
        //     self.loading = false;
        //   });
        // });
      } else {
        this.loading = true;
        // this.organization = this.$store.state.app.user.profile.organization;
        this.organization = this.$store.state.app.org_id;
        /////////// load vulnerabilities /////////////
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/vulns?page=" +
            this.currentPage +
            "&group_id=" +
            this.asset_group_id,
        };
        this.$http(options).then((res) => {
          this.items = res.data.results;
          this.rows = res.data.length;
          this.total = Math.ceil(res.data.count / this.perPage);
          this.total_vulnerabilties_count = res.data.count;
          this.loading = false;
        });
        //////////// Load Asset Groups ////////////////
        // const a_options = {
        //   method: "GET",
        //   headers: { "content-type": "application/json" },
        //   url: process.env.VUE_APP_BASEURL + "asset/group",
        // };
        // var self = this;
        // this.$http(a_options).then((res) => {
        //   res.data.map(function (value, key) {
        //     let a = {
        //       value: res.data[key].group_id,
        //       text: res.data[key].group_name,
        //     };
        //     self.group_filter_options.push(a);
        //   });
        // });

        //////////// Load users for task assignment ///////////
      }

      //}
      //////////////////////////////////////////////////////
    },
    setSort(sortValue) {
      this.isRemediation = sortValue;
      this.searchFn();
    },
    loadGroupsAndSearch: function () {
      // this.group_filter_options = [{ value: null, text: "--Select Group--" }];
      // this.group_filter = null;

      this.group_filter_options = [];
      this.group_filter = [];

      if (this.organization == null) {
        this.filter = [];
        this.is_search_on = false;
        this.items = [];
        return;
      }
      // Load Groups

      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/group?org_id=" +
          this.organization,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        res.data.results.map(function (value, key) {
          let a = {
            value: res.data.results[key].group_id,
            text: res.data.results[key].group_name,
          };
          // console.log(res.data[key].profile.organization)
          self.group_filter_options.push(a);
        });
      });

      //Then search
      this.searchFn();
    },

    searchFn: function (reset = true) {
      this.isloading = true;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "asset/vulns?query=true&page=" +
        this.currentPage +
        "&items_per_page=" +
        this.perPage +
        "&org_id=" +
        this.organization +
        "&group_id=" +
        this.asset_group_id;
      if (this.isRemediation) {
        url = url + "&cve_priority=" + this.isRemediation;
      }
      this.query = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.total_vulnerabilties_count = res.data.count;
        //setInterval(function(){self.updateCheckAll()},3000);
        // this.updateCheckAll();
        this.loading = false;
        this.isloading = false;
      });
    },
    showSidebar: function (data) {
      this.vuln = data.item;
      this.asset_id = data.item.asset.asset_id;
      // this.organization_id = data.item.asset.assetOwner.profile.organization;
      this.organization_id = this.$store.state.app.org_id;
      this.activeTab = 0;
      console.log("vuln", data);
      // this.$refs['taskSideBar'].show();
      this.$root.$emit("bv::toggle::collapse", "sidebar-vuln-handler");
      // this.isTaskHandlerSidebarActive = true
      // this.scrollTo();
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
