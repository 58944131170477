<template>
  <b-card-code>
    <div class="d-flex justify-content-between mb-1">
      <h4>{{ group_name }}</h4>
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <div class="row ml-auto">
      <button
        @click="showSidebar()"
        type="button"
        class="btn btn-primary btn-sm mr-1"
      >
        Add Assets to Group
      </button>
      <!-- <router-link :to="'/assets/upload-nessus-report' ">
        <button
          @click="addNessusReport()"
          type="button"
          class="btn ml-auto btn-outline-primary mr-3"
        >
          Upload Vulnerability
        </button></router-link
      > -->
      <button
        @click="addNessusReport()"
        type="button"
        class="btn btn-outline-primary mr-1 btn-sm"
      >
        Upload Vulnerability
      </button>
      <button
        @click="openScanOptions()"
        type="button"
        v-if="
          this.$store.state.app.user.permissions.includes(
            'scan.add_scanscheduler'
          )
        "
        class="btn btn-outline-danger mr-1 btn-sm"
      >
        Scan
      </button>
      <button
        @click="generateReport()"
        type="button"
        class="btn btn-outline-warning mr-1 btn-sm"
      >
        Generate Report
      </button>

      <button
        v-if="
          this.$store.state.app.user.permissions.includes(
            'asset.list_all_org_assets'
          )
        "
        @click="sidebarAssign()"
        type="button"
        class="btn btn-outline-warning mr-1 btn-sm"
      >
        Assign
      </button>
    </div>
    <!-- {{ group_id }} -->

    <!-- {{organization}} -->
    <!-- Search Form -->
    <div class="row">
      <div class="col-12 mt-2">
        <div class="row" v-if="group_data">
          <div class="col">
            <b-card class="text-left bg-light-primary w-100 border-primary">
              <h5 class="text-primary">Critical Risk Assets</h5>
              <div>
                <div class="d-flex justify-content-end">
                  <h4>
                    <b>{{ group_data.critical_risk_assets }}%</b>
                  </h4>
                </div>
              </div>
            </b-card>
          </div>
          <div class="col d-flex align-items-center align-items-stretch">
            <b-card class="text-left bg-light-primary w-100 border-primary">
              <h5 class="text-primary">High Risk Assets</h5>
              <div>
                <div class="d-flex justify-content-end">
                  <h4>
                    <b>{{ group_data.high_risk_assets }}%</b>
                  </h4>
                </div>
              </div>
            </b-card>
          </div>
          <div class="col">
            <b-card class="text-left bg-light-primary w-100 border-primary">
              <h5 class="text-primary">Medium Risk Assets</h5>
              <div>
                <div class="d-flex justify-content-end">
                  <h4>
                    <b>{{ group_data.medium_risk_assets }}%</b>
                  </h4>
                </div>
              </div>
            </b-card>
          </div>
          <div class="col">
            <b-card class="text-left bg-light-primary w-100 border-primary">
              <h5 class="text-primary">Low Risk Assets</h5>
              <div>
                <div class="d-flex justify-content-end">
                  <h4>
                    <b>{{ group_data.low_risk_assets }}%</b>
                  </h4>
                </div>
              </div>
            </b-card>
          </div>
          <div class="col">
            <b-card class="text-left bg-light-primary w-100 border-primary">
              <h5 class="text-primary">Safe Assets</h5>
              <div>
                <div class="d-flex justify-content-end">
                  <h4>
                    <b>{{ group_data.safe_assets }}%</b>
                  </h4>
                </div>
              </div>
            </b-card>
          </div>
          <!-- <div class="col">
            <b-card class="text-left bg-light-primary w-100 border-primary">
              <h5 class="text-primary">Risk Unknown</h5>
              <div>
                <div class="d-flex justify-content-end">
                  <h4>
                    <b>{{ group_data.risk_unknown }}%</b>
                  </h4>
                </div>
              </div>
            </b-card>
          </div> -->
        </div>
        <!-- <b-card >
          <e-charts
            class="w-100"
            ref="bar"
            autoresize
            :options="chartOptionsBar"
            theme="theme-color"
            auto-resize
          />
        </b-card> -->
      </div>
    </div>
    <b-tabs @input="handleTabChange">
      <b-tab title="Assets" active>
        <b-card-code style="margin-top: 0px">
          <b-card-code class="bg-light-secondary mb-1" title="Search">
            <b-form inline style="display: inline-block; width: 100%">
              <div class="row" style="width: 100%">
                <b-form-select
                  class="ml-1"
                  style="width: 25%"
                  v-model="risk_level"
                  :options="risk_level_filter_options"
                >
                </b-form-select>
                <b-form-input
                  class="ml-1"
                  id="name"
                  v-model="name_filter"
                  style="width: 50%"
                  name="name"
                  placeholder="Search with Name or Nick Name"
                />
                <b-button
                  style="width: 12%"
                  class="ml-1"
                  variant="primary"
                  @click="searchFn"
                >
                  <span v-if="isloading"
                    ><b-spinner type="border" small></b-spinner> Please
                    wait</span
                  >
                  <span v-else>
                    <feather-icon icon="SearchIcon" class="mr-50" />Search</span
                  >
                </b-button>
              </div>
            </b-form>
          </b-card-code>
          <b-form inline style="">
            <b-form-checkbox
              class="ml-1"
              name="selected-items"
              v-model="isCheckAll"
              label="Select All"
              @change="checkAll()"
            >
              Select all in this page
            </b-form-checkbox>

            <b-form-checkbox
              class="ml-1"
              name="selected-items"
              v-model="isCheckAllFilterResults"
              label="Select All"
              v-if="is_search_on"
            >
              Select all assets that matches this search
            </b-form-checkbox>

            <div class="clearfix ml-auto">
              <b-form-select
                v-model="selected_action"
                :options="with_selected_options"
                @change="doBulkAction"
              >
              </b-form-select>
            </div>
          </b-form>
        </b-card-code>
        <div class="d-flex justify-content-center mb-1" v-if="loading">
          <b-spinner class="float-right" label="Floated Right" />
        </div>
        <!-- {{items}} -->
        <!-- Search Form ends here-->
        <div v-if="!loading" class="clearfix">
          Total of {{ total_assets_counts }} Assets
        </div>
        <b-table
          responsive
          id="assetTable"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          ref="table"
          v-if="!loading"
          hover
          style="min-height: 200px"
        >
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
          <template #cell(selected_assets)="data">
            <b-form-checkbox
              name="selected-items"
              v-model="selectedAssets"
              :value="data.item.asset_id"
              @change="updateCheckAll()"
            >
            </b-form-checkbox>
          </template>
          <template #cell(asset_name)="data">
            <router-link :to="'/assets/scan/' + data.item.asset_id">{{
              data.item.asset_name
            }}</router-link>
          </template>
          <template #cell(risk_level)="data">
            <b-badge
              style="background-color: #991d28"
              v-if="data.item.risk_level == 5"
              >Critical</b-badge
            >
            <b-badge variant="danger" v-else-if="data.item.risk_level == 4"
              >High</b-badge
            >
            <b-badge variant="warning" v-else-if="data.item.risk_level == 3"
              >Medium</b-badge
            >
            <b-badge variant="primary" v-else-if="data.item.risk_level == 2"
              >Low</b-badge
            >
            <b-badge variant="success" v-else-if="data.item.risk_level == 1"
              >Safe</b-badge
            >
            <b-badge variant="secondary" v-else>Unknown</b-badge>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              v-if="$store.state.app.user.permissions.includes('scan.add_scan')"
              size="sm"
              class="ml-1"
              variant="primary"
              right
            >
              <template v-slot:button-content>
                <span v-if="isloadingSearch"
                  ><b-spinner type="border" small></b-spinner> Please wait</span
                >
                <span v-else>Scan Now</span>
                <!-- <b-spinner type="grow" small></b-spinner> -->
              </template>
              <!-- <b-dropdown-item
            v-for="item in data.item.agent_configurations"
            :key="item.agent_conf_id"
            @click="openCModal(item.agent_conf_id, item.agent.agent_meta)"
            >{{ item.agent.agent_name }}</b-dropdown-item
          >
         -->
              <b-dropdown-item
                v-for="item in agent_types"
                :key="item.agent_type_id"
                :disabled="item.is_active == false"
                v-b-tooltip.hover.v-dark
                :title="
                  item.is_active == false
                    ? 'Upgrade your Plan'
                    : item.agent_type_nicename
                "
                @click="
                  openCModal(
                    data.item.asset_id,
                    item.agent_type_id,
                    item.agent_type_code
                  )
                "
                >{{ item.agent_type_nicename }}</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              size="sm"
              class="m-1"
              variant="outline-primary"
              text="Actions"
              right
            >
              <b-dropdown-item @click="scanAsset(data.item.asset_id)"
                >Manage</b-dropdown-item
              >
              <b-dropdown-item @click="editAsset(data.item.asset_id)"
                >Edit</b-dropdown-item
              >
              <b-dropdown-divider />
              <b-dropdown-item @click="removeAsset(data.item.asset_id)"
                >Remove from group</b-dropdown-item
              >
              <b-dropdown-item
                @click="gotoDelete(data.item.asset_id, data.item.asset_name)"
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>

        <b-pagination-nav
          v-model="currentPage"
          :number-of-pages="total"
          :numberOfPages="total"
          :link-gen="linkGen"
          :per-page="perPage"
          :align="pagination_pos"
          aria-controls="assetTable"
          use-router
          v-if="!loading"
        />
      </b-tab>
      <b-tab
        title="Schedules "
        lazy
        v-if="
          this.$store.state.app.user.permissions.includes(
            'scan.list_scanschedule'
          )
        "
        event-key="secondTabKey"
      >
        <b-card-code title="">
          <div
            class="d-flex justify-content-center mb-1"
            v-if="loadingSchedule"
          >
            <b-spinner class="float-right" label="Floated Right" />
          </div>
          <div v-if="!loadingSchedule">
            <b-table
              responsive
              id="allscansTable"
              :fields="schedule_fields"
              :items="schedule_items"
              :per-page="perPage"
              ref="table"
              v-if="schedule_items.length"
              hover
            >
              <template #cell(schedule_id)="data">
                SC-{{ data.item.schedule_id }}
              </template>
              <template #cell(asset_name)="data">
                <!-- <div class="primary" v-if="data.item.asset!=null">
            <router-link :to="'/assets/scan/' + data.item.asset.asset_id"
              >{{ data.item.asset.asset_nickname }} ({{
                data.item.asset.asset_name
              }})</router-link
            >
          </div> -->
                <div class="primary" v-if="data.item.assetgroup != null">
                  <router-link
                    :to="'/assets/group/' + data.item.asset_group + '/assets'"
                    >{{ data.item.assetgroup.group_name }} (Asset
                    Group)</router-link
                  >
                </div>
              </template>

              <template #cell(last_run_at)="data">
                {{ data.item.last_run_at | formatdate }}
              </template>
              <template #cell(scan_scheduled_day)="data">
                <span v-if="data.item.scan_scheduled_day">
                  {{ data.item.scan_scheduled_day }}
                  {{ data.item.scan_schedule_time }}
                </span>
                <span v-else>
                  {{ data.item.scan_schedule_date }}
                  {{ data.item.scan_schedule_time }}
                </span>
                <!-- {{ data.item.scan_scheduled_day | formatdate }} -->
              </template>
              <template #cell(scan_schedule_type)="data">
                <b-badge
                  variant="primary"
                  v-if="data.item.scan_schedule_type == 1"
                  >Daily</b-badge
                >
                <b-badge
                  variant="warning"
                  v-else-if="data.item.scan_schedule_type == 2"
                  >Weekly</b-badge
                >
                <b-badge
                  variant="success"
                  v-else-if="data.item.scan_schedule_type == 3"
                  >Weekends</b-badge
                >
                <b-badge
                  variant="success"
                  v-else-if="data.item.scan_schedule_type == 4"
                  >Continous</b-badge
                >
                <b-badge
                  variant="warning"
                  v-else-if="data.item.scan_schedule_type == 5"
                  >Monthly</b-badge
                >
                <b-badge
                  variant="info"
                  v-else-if="data.item.scan_schedule_type == 6"
                  >Quarterly</b-badge
                >
                <b-badge
                  variant="success"
                  v-else-if="data.item.scan_schedule_type == 7"
                  >Once in six-Months</b-badge
                >
                <b-badge
                  variant="success"
                  v-else-if="data.item.scan_schedule_type == 8"
                  >Once in a year
                </b-badge>
                <b-badge variant="danger" v-else>NA</b-badge>
              </template>
              <template #cell(scan_input_meta)="data">
                <b-badge
                  variant="danger"
                  v-if="
                    data.item.agenttype &&
                    data.item.agenttype.agent_type_code == 'masscan'
                  "
                  >{{ data.item.scan_input_meta.type }}</b-badge
                >
              </template>
              <template #cell(is_paused)="data">
                <div class="d-flex justify-content-start">
                  <b-badge
                    variant="light-success"
                    v-if="data.item.is_paused == 0"
                    >Active</b-badge
                  >
                  <b-badge variant="light-danger" v-else>Inactive</b-badge>
                </div>
                <b-badge
                  variant="danger"
                  v-if="data.item.is_paused == 0"
                  style="cursor: pointer"
                  title="Click to pause this schedule."
                  @click="changeSchedule(data.item.schedule_id, 1)"
                  >Make Inactive</b-badge
                >
                <b-badge
                  variant="success"
                  v-if="data.item.is_paused == 1"
                  style="cursor: pointer"
                  title="Click to resume this schedule."
                  @click="changeSchedule(data.item.schedule_id, 0)"
                  >Make Active</b-badge
                >
              </template>
              <template #cell(actions)="data">
                <button
                  @click="
                    gotoDeleteShedule(
                      data.item.schedule_id,
                      data.item.assetgroup.group_name
                    )
                  "
                  type="button"
                  class="btn btn-danger btn-sm"
                  size="sm"
                  style="position: relative"
                >
                  Delete
                </button>
              </template>
            </b-table>
            <div v-else>
              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
              >
                <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
                <h3 class="font-weight-bolder">No Data Found</h3>
              </div>
            </div>
          </div>

          <b-pagination-nav
            v-model="currentPage"
            :numberOfPages="total"
            :total-rows="rows"
            :link-gen="linkGen"
            :per-page="perPage"
            :align="pagination_pos"
            aria-controls="allscansTable"
            use-router
            v-if="!loadingSchedule"
          />
        </b-card-code>
      </b-tab>
      <b-tab title="Vulnerabilities" lazy>
        <GroupVuln :asset_group_id="group_id" :organization="organization" />
      </b-tab>
      <!-- <b-tab title="Group Scans" lazy>
        <GroupScan
          ref="nessussScanList"
          :agent_types="agent_types"
          :group_id="group_id"
        />
      </b-tab> -->
      <b-tab title="Reports" lazy>
        <GroupReport :asset_group_id="group_id" :organization="organization" />
      </b-tab>
      <b-tab
        title="Access"
        lazy
        v-if="
          this.$store.state.app.user.permissions.includes(
            'asset.list_all_org_assets'
          )
        "
      >
        <GroupAccess
          ref="groupAssign"
          :asset_group_id="group_id"
          :sidebarAssign="sidebarAssign"
        />
      </b-tab>
    </b-tabs>
    <b-modal
      ref="modal-customize-meta"
      title="Customise Scan"
      hide-footer
      size="lg"
    >
      <p class="my-1">
        Better not edit if you have no idea! Simply press the scan button nad
        you are done!
      </p>
      <AddScan
        :agent_conf_id="agent_conf_id"
        :scan_input_meta="scan_input_meta"
        :callBack="afterScan"
      />
    </b-modal>
    <b-modal
      ref="modal-customize-meta-burp"
      title="Customise Scan"
      hide-footer
      size="lg"
    >
      <p class="my-1">Customize your scan here.</p>
      <AddBurpScan
        :agent_type_id="agent_type_id"
        :asset_id="asset_id"
        :scan_input_meta="scan_input_meta"
        :callBack="afterScan"
      />
    </b-modal>
    <b-modal ref="modal-normal-scan" title="Scan Options" hide-footer size="lg">
      <AddNormalScan
        :agent_type_id="agent_type_id"
        :asset_id="asset_id"
        :callBack="afterScan"
        :agent_type="agent_type"
      />
    </b-modal>

    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      right
      width="600px"
    >
      <AttachAsset
        :org_id="organization"
        :sidebar_title="sidebar_title"
        :assetTypes="assetTypes"
        :group_id="group_id"
        :group_owner_id="group_owner_id"
        :closeSidebar="closeSidebar"
      />
    </b-sidebar>
    <b-sidebar
      id="sidebar-scan-options"
      ref="sidebar-scan-options"
      sidebar-class="sidebar-lg"
      width="700px"
      bg-variant="white"
      shadow
      backdrop
      right
    >
      <ScanOptions
        :asset_group_id="group_id"
        :callingback="loadSchedules"
        :closeScanSidebar="closeScanSidebar"
      />
    </b-sidebar>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete
        <span class="text-primary">"{{ asset_name }}"</span> ?
      </b-card-text>
      <div style="margin-top: 5px">
        <span class="font-italic"
          >This action will remove the following associated records:
        </span>
        <ul>
          <li>Vulnerabilities</li>
          <li>Scans & Schedules</li>
          <li>Tasks</li>
        </ul>
      </div>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteAsset()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openScheduleDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete
        <span class="text-primary">" {{ group_name }} "</span> ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteShedule()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
    <b-sidebar
      id="sidebar-assign-handler"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      right
      width="600px"
    >
      <GroupMapping
        :org_id="org_id"
        :closeSidebar="closeAssignSidebar"
        :group_filter="group_id"
      />
    </b-sidebar>
    <b-modal
      ref="report_generator"
      title="Generate Report"
      hide-footer
      size="lg"
      no-close-on-backdrop
    >
      <!-- {{organization}} -->
      <ReportGenerator
        :org_id="org_id"
        :report_type="parseInt(2)"
        :group_id="group_id"
        :parentCallBack="closeModal"
      />
    </b-modal>
    <b-modal
      ref="modal-nessus"
      title="Nessus Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <NessusScan
        :agent_type_id="agent_type_id"
        :asset_id="asset_id"
        :callBack="afterScan"
      />
    </b-modal>
    <b-modal
      ref="modal-bulk-delete"
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete the selected assets ?
      </b-card-text>
      <div style="margin-top: 10px">
        <span class="font-italic"
          >This action will remove the following associated records:
        </span>
        <ul class="font-italic">
          <li>Vulnerabilities</li>
          <li>Scans & Schedules</li>
          <li>Tasks</li>
        </ul>
      </div>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteAssetsAsBulk()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import moment from "moment";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import AddNormalScan from "../Asset/components/AddNormalScan.vue";
import AddBurpScan from "../Asset/components/AddBurpScan.vue";
import NessusScan from "../Asset/components/NessusScanner.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge,
  BFormSelect,
  BFormGroup,
  BForm,
  BFormInput,
  BSidebar,
  VBToggle,
  BFormCheckbox,
  BSpinner,
  BTabs,
  BTab,
  VBTooltip,
} from "bootstrap-vue";
import AddScan from "../Asset/components/AddScan.vue";
import AttachAsset from "./attach_assets.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ScanOptions from "../AssetGroup/componets/group_scan_options.vue";
import GroupVuln from "../AssetGroup/componets/group_vuln.vue";
import GroupReport from "../AssetGroup/componets/group_report.vue";
import GroupAccess from "./componets/group_access.vue";
import GroupMapping from "./componets/group_mapping.vue";
import ReportGenerator from "./../common/components/ReportGenerator.vue";
import GroupScan from "./componets/group_scan_list.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BTabs,
    BTab,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormSelect,
    BFormGroup,
    BForm,
    BFormInput,
    BModal,
    AddScan,
    BSidebar,
    VBToggle,
    AttachAsset,
    BFormCheckbox,
    BSpinner,
    ECharts,
    ScanOptions,
    GroupVuln,
    GroupReport,
    AddNormalScan,
    AddBurpScan,
    GroupAccess,
    GroupMapping,
    ReportGenerator,
    NessusScan,
    GroupScan,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      pagination_pos: "center",
      group_name: "",
      group_id: parseInt(this.$route.params.id),
      organization: 0,
      // org_id: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : 0,

      // Call orgaization API and return in this format
      // org_id: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      org_id: this.$store.state.app.org_id,
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "selected_assets", label: "select" },
        { key: "asset_name", label: "Target" },
        { key: "risk_level", label: "Risk Level" },
        { key: "asset_nickname", label: "Name" },
        { key: "assetType.asset_type_name", label: "Asset Type" },
        // { key: 'assetOwner.username', label: 'Owner' },
        //{ key: 'asset.assetOwner.profile.o_profile.org_name', label: 'Organization' },
        { key: "actions", label: "Actions" },
      ],
      asset_name: "",
      asset_count: 0,
      // Scanner attachment modal
      agent_conf_id: "",
      scan_input_meta: "",
      name_filter: "",
      sidebar_title: "",
      assetTypes: [],
      group_owner_id: 0,
      risk_level: null,
      risk_level_filter_options: [
        { value: null, text: "--Select Risk--" },
        { value: 0, text: "Unknown" },
        { value: 1, text: "Safe" },
        { value: 2, text: "Low" },
        { value: 3, text: "Medium" },
        { value: 4, text: "High" },
        { value: 5, text: "Critical" },
      ],

      //For MULTI GROUPING
      selectedAssets: [],
      with_selected_options: [
        { value: null, text: "--With Selected--" },
        { value: "delete", text: "Delete" },
        { value: "remove", text: "Remove from group" },
        // { value: "add_to_group", text: "Add to Group" },
      ],
      selected_action: null,
      isCheckAllFilterResults: false,
      isCheckAll: false,
      filter: [],
      is_search_on: false,
      loading: false,
      loadingSchedule: false,
      total_assets_count: 0,
      total_assets_counts: 0,
      group_data: [],

      // Scanner attachment modal
      agent_types: [],
      agent_type: null,
      agent_type_id: 0,
      asset_id: 0,
      scan_input_meta: "",
      isloading: "",
      openDeleteModal: false,
      isloadingSearch: false,

      // group schedule
      schedule_items: [],
      schedule_currentPage: 1,
      schedule_perPage: 6,
      schedule_rows: 0,
      schedule_fields: [
        { key: "schedule_id", label: "#" },
        // { key: "asset_name", label: "Asset Group Name" },
        { key: "agenttype.agent_type_nicename", label: "Type" },
        // { key: "scan_input_meta", label: "Scan Info" },
        { key: "last_run_at", label: "Last Run At" },
        { key: "scan_scheduled_day", label: "Scheduled Time" },
        { key: "timezone", label: "Timezone" },
        { key: "is_paused", label: "Status" },
        { key: "scan_schedule_type", label: "Schedule" },
        { key: "actions", label: "Actions" },
      ],
      openScheduleDeleteModal: false,
      groupName: "",
      schedule_id: "",
      // chartOptionsBar: {
      //   xAxis: {
      //     data: [],
      //   },
      //   yAxis: {
      //     type: "value",
      //   },
      //   series: [
      //     {
      //       type: "bar",
      //       data: [],
      //       itemStyle: {
      //         color: "#127ac2",
      //       },
      //     },
      //   ],
      // },
    };
  },

  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm");
      }
    },
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
  },
  // created: function () {
  //   this.load();
  // },
  mounted() {
    this.load();
    this.loadAgentTypes();
    //  this.loadSchedules();
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    handleTabChange(tabId) {
      if (tabId === 1) {
        this.loadSchedules();
      }
    },
    load: function () {
      this.loading = true;
      this.assetTypes = [];
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        // url: process.env.VUE_APP_BASEURL+'asset/group/'+this.$route.params.id,
        url:
          process.env.VUE_APP_BASEURL +
          "asset/asset?group=" +
          this.$route.params.id +
          "&page=" +
          this.currentPage,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.length;
        if (res.data.count > 0)
          this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
        this.total_assets_counts = res.data.count;
      });
      var self = this;
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "asset/group/" + this.$route.params.id,
      }).then((res) => {
        // alert(res.data.group_owner_id)
        self.group_name = res.data.group_name;
        self.organization = res.data.groupOwner.profile.organization;
        self.group_owner_id = res.data.group_owner_id;
        self.asset_count = res.data.asset_count;
      });
      var self = this;
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "dashboard/v1/group_data?org_id=" +
          this.org_id +
          "&group_id=" +
          this.group_id,
      }).then((res) => {
        console.log("L", res.data);
        if (res.data) {
          // alert("data here")
          this.group_data = res.data[0];
          delete res.data[0].groupname;
        }

        this.group_data_loading = false;
      });

      // load asset types for sidebar
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/asset-type",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let org = {
            value: res.data[key].asset_type_id,
            text: res.data[key].asset_type_name,
          };
          // console.log(res.data[key].profile.organization)
          self.assetTypes.push(org);
        });
      });
    },
    loadAgentTypes: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/type",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        // console.log(res.data)
        this.agent_types = res.data;
        // console.log(this.conf_items)
        this.agent_types = this.agent_types.filter(
          (item) =>
            item.agent_type_code !== "amass" &&
            item.agent_type_code !== "emailharvester" &&
            item.agent_type_code !== "hibp" &&
            item.agent_type_code !== "cidr" &&
            item.agent_type_code !== "dsscan" &&
            item.agent_type_code !== "prowler" &&
            item.agent_type_code !== "attack_surface"
        );
      });
    },
    // openCModal: function (id, meta) {
    //   this.agent_conf_id = id;
    //   this.scan_input_meta = meta;
    //   this.$refs["modal-customize-meta"].show();
    // },

    doScanNow: function () {
      this.isloading = true;
      let data = {
        agent_type: this.agent_type_id,
        asset_id: this.asset_id,
        scan_created_by: this.userId,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/scan/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res, ">>>>>>>>>>>");
        if (res.data.errors) {
          this.load();
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.errors,
              // title: "Successfully added the scan request",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          this.load();
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully added the scan request",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
        this.isloading = false;
      });
    },
    openCModal: function (asset_id, id, type) {
      this.agent_type = type;
      this.agent_type_id = id;
      this.asset_id = asset_id;
      if (type == "masscan") this.$refs["modal-normal-scan"].show();
      if (type == "burpsuit") this.$refs["modal-customize-meta-burp"].show();
      if (type == "nessus") this.$refs["modal-nessus"].show();
      // if (type == "nessus") this.doScanNow();
      if (type == "wpscan") this.doScanNow();
      if (type == "dsscan") this.doScanNow();
      if (type == "ipreputation") this.doScanNow();
      if (type == "ssllabs") this.doScanNow();
      if (type == "zap") this.doScanNow();
      if (type == "attack_surface") this.$refs["modal-normal-scan"].show();
    },
    afterScan: function () {
      this.$refs["modal-customize-meta-burp"].hide();
      this.$refs["modal-normal-scan"].hide();
      this.$refs["modal-nessus"].hide();
      this.$refs["modal-customize-meta"].hide();
      this.$refs["modal-schedule-scan"].hide();
      // this.loadScans()
    },
    addAsset() {
      this.$router.push({ name: "Add Asset" });
    },
    editAsset(id) {
      this.$router.push({ name: "Edit Asset", params: { id: id } });
    },
    scanAsset(id) {
      this.$router.push({ name: "Scan Asset", params: { id: id } });
    },
    gotoDelete(asset_id, asset_name) {
      this.openDeleteModal = true;
      this.asset_id = asset_id;
      this.asset_name = asset_name;
    },
    deleteAsset() {
      // if (confirm("Do you really want to delete?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.asset_id +
          "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
      // }
    },
    addNessusReport() {
      this.$router.push({
        name: "Upload Nessus Vulnerability",
        params: { group_id: this.$route.params.id },
      });
      //  org_id: this.org_id
    },
    removeAsset(asset_id) {
      if (confirm("Do you really want to delete?")) {
        let assets = [];
        assets.push(asset_id);
        let data = {
          group_id: this.$route.params.id,
          assets: assets,
        };
        const a_options = {
          method: "POST",
          data: data,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "asset/group/remove-assets/",
        };
        var self = this;
        this.$http(a_options).then((res) => {
          if (res.data.status == "success") {
            this.load();
          }
        });
      }
    },

    searchFn: function (reset = true) {
      this.isloadingSearch = true;
      this.is_search_on = false;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "asset/asset?query=true&page=" +
        this.currentPage;

      url = url + "&group=" + this.$route.params.id;
      if (this.name_filter != null && this.name_filter != "") {
        this.filter.push({ name: this.name_filter });
        url = url + "&name=" + this.name_filter;
      }
      if (this.risk_level != null) {
        this.filter.push({ risk_level: this.risk_level });
        url = url + "&risk_level=" + this.risk_level;
      }
      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
        this.rows = res.data.length;
        if (res.data.count > 0)
          this.total = Math.ceil(res.data.count / this.perPage);
        // this.total = res.data.count / this.perPage;
        this.total_assets_count = res.data.count;
        this.updateCheckAll();
        this.loading = false;
        this.isloadingSearch = false;
      });
    },
    showSidebar: function (data) {
      // this.$refs['taskSideBar'].show();
      this.sidebar_title = "Add assets to " + this.group_name;
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      // this.isTaskHandlerSidebarActive = true
    },
    openScanOptions: function (id, meta) {
      this.$root.$emit("bv::toggle::collapse", "sidebar-scan-options");
    },
    closeScanSidebar: function () {
      this.$refs["sidebar-scan-options"].hide();
      this.$refs.nessussScanList.load();
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      this.load();
    },
    checkAll: function () {
      if (this.isCheckAll) {
        this.items.forEach((item) => {
          const index = this.selectedAssets.indexOf(item.asset_id);
          if (index < 0) this.selectedAssets.push(item.asset_id);
        });
      } else {
        this.items.forEach((item) => {
          const index = this.selectedAssets.indexOf(item.asset_id);
          this.selectedAssets.splice(index, 1);
          // }
        });
      }
    },
    updateCheckAll: function () {
      var if_all = 0;
      this.items.forEach((item) => {
        // console.log("item", item.asset_id);
        const index = this.selectedAssets.indexOf(item.asset_id);
        if (index >= 0) {
          console.log(index);
          if_all++;
        }
      });
      console.log("selected", if_all);
      if (if_all == this.items.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    doBulkAction: function () {
      if (!this.selectedAssets.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      }
      switch (this.selected_action) {
        case "delete":
          // if (confirm("Do you really want to delete the selected assets?")) {
          //   this.deleteAssetsAsBulk();
          // }
          this.loadBulkDelete();
          this.selected_action = null;
          break;
        case "remove":
          this.removeAssetsAsBulk();
          this.selected_action = null;
          break;
        default:
          break;
      }
    },
    loadBulkDelete: function () {
      this.$refs["modal-bulk-delete"].show();
    },
    deleteAssetsAsBulk: function () {
      this.loading = true;
      var self = this;
      let data = {
        assets: this.selectedAssets,
      };
      this.filter.push({ group_id: this.$route.params.id });
      if (this.isCheckAllFilterResults && this.is_search_on) {
        data.filters = this.filter;
      }
      this.$http({
        method: "DELETE",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/org-asset/bulk-delete/",
      }).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedAssets = [];
          self.searchFn(false);
        } else {
          // debugger;
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        self.loading = false;
      });
      this.closeModal();
    },
    removeAssetsAsBulk() {
      if (
        confirm(
          "Do you really want to remove the selected assets from this group?"
        )
      ) {
        this.loading = true;
        let data = {
          group_id: this.$route.params.id,
          assets: this.selectedAssets,
        };
        const a_options = {
          method: "POST",
          data: data,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "asset/group/remove-assets/",
        };
        var self = this;
        this.$http(a_options).then((res) => {
          if (res.data.status == "success") {
            this.searchFn(false);
            this.loading = false;
          }
        });
      }
    },
    loadSchedules: function (id) {
      this.loadingSchedule = true;
      if (
        this.$store.state.app.user.permissions.includes(
          "scan.list_scanschedule"
        )
      ) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "scan/schedule/asset-group-scans?is_schedule=0&asset_group=" +
            this.$route.params.id,
        };

        var self = this;
        this.$http(options).then((res) => {
          // this.schedule_items = res.data.results.filter(
          //   (item) => item.scan_schedule_type != 0
          // );
          this.schedule_items = res.data.results;
          this.loadingSchedule = false;
        });
      }
    },

    changeSchedule: function (id, value) {
      let data = {
        schedule_id: id,
        is_paused: value,
      };
      const s_options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "scan/schedule/toggle-schedule-status/",
      };
      var self = this;
      this.$http(s_options).then((res) => {
        self.loadSchedules();
      });
    },

    gotoDeleteShedule(schedule_id, group_name) {
      this.openScheduleDeleteModal = true;
      this.schedule_id = schedule_id;
      this.groupName = group_name;
    },
    deleteShedule() {
      let data = {
        schedule_id: this.schedule_id,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/schedule/delete-asset-group-schedule/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.openScheduleDeleteModal = false;
        this.loadSchedules();
      });
    },
    // deleteShedule() {
    //   const options = {
    //     method: "DELETE",
    //     headers: { "content-type": "application/json" },
    //     url:
    //       process.env.VUE_APP_BASEURL +
    //       "scan/schedule/" +
    //       this.schedule_id +
    //       "/",
    //   };
    //   var self = this;
    //   this.$http(options).then((res) => {
    //     if (res.data == "") {
    //       self.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: "Successfully deleted",
    //           icon: "TrashIcon",
    //           variant: "success",
    //         },
    //       });
    //       this.openScheduleDeleteModal = false;
    //       this.loadSchedules();
    //     }
    //   });
    // },
    sidebarAssign: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-assign-handler");
    },
    closeAssignSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-assign-handler");
      this.$refs.groupAssign.load();
      this.load();
    },
    generateReport() {
      this.$refs["report_generator"].show();
    },
    closeModal() {
      this.$refs["report_generator"].hide();
      this.$refs["modal-bulk-delete"].hide();
    },
    afterScan(callBackParams) {
      this.$refs[callBackParams].hide();
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.text-purple {
  color: #7367f0 !important;
}
.bg-card-grey {
  background-color: #ecf0f3;
}
</style>
