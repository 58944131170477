<template>
  <b-col cols="12">
    <b-card-code title="Assigned Users" no-body>
      <div v-if="!isLoading">
        <b-table
          v-if="items.length > 0"
          responsive
          id="userTable"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          hover
        >
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
          <template #cell(full_name)="data">
            <div
              class="d-flex justify-content-between"
              style="white-space: nowrap; overflow: hidden"
            >
              <span style="text-overflow: ellipsis" class="text-capitalize">{{
                data.item.user_id.full_name
              }}</span>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button
              @click="gotoDelete(data.item.user_id.id, data.item.user_id.full_name)"
              type="button"
              variant="outline-danger"
              style="position: relative"
              size="sm"
            >
              Remove
            </b-button>
          </template>
        </b-table>
        <div v-else>
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
          >
            <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
            <h3 class="font-weight-bolder">No Data Found</h3>
            <p>
              Navigate to Assign User ,
              <span
                class="font-weight-bold text-success cursor-pointer"
                @click="sidebarAssign()"
                >Click here</span
              >
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <b-spinner></b-spinner>
      </div>

      <b-modal
        id="modal-danger"
        hide-footer
        modal-class="modal-danger"
        centered
        title="Remove.. !"
        v-model="openAssigneeDeleteModal"
        size="lg"
      >
        <b-card-text>
          Do you really want to Remove
          <span class="text-primary">" {{ assignee_name }} "</span> ?
        </b-card-text>
        <div class="d-flex justify-content-end">
          <b-button @click="deleteAssignee()" variant="danger" size="sm">
            Remove
          </b-button>
        </div>
      </b-modal>
    </b-card-code>
  </b-col>
</template>


<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BSpinner,
    // VBToggle,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      asset_id: "",
      organization_id: "",
      pagination_pos: "center",
      task_title: "",

      items: [],
      currentPage: 1,
      perPage: 10,
      baseline_rows: 0,
      fields: [
        { key: "index", label: "#" },
        { key: "full_name", label: "Full Name" },
        { key: "created_at", label: "Assigned Date" },
        { key: "actions", label: "Actions" },
      ],
      openAssigneeDeleteModal: false,
      assignee_id: null,
      assignee_name: "",
      isLoading: false,
    };
  },
  props: {
    asset_group_id: {
      type: Number,
      required: true,
    },
    organization: {
      type: Number,
      required: true,
    },
    sidebarAssign: {
      type: Function,
      required: true,
    },
  },
  created() {
    this.load();
  },
  methods: {
    load: function () {
      //   this.asset_id = parseInt(this.$route.params.id);
      this.isLoading = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/group/" +
          this.asset_group_id +
          "/assigned-users",
      };
      var self = this;
      this.$http(options).then((res) => {
        res.data.results.forEach((item) => {
          item.created_at = moment(item.created_at).format("DD-MMM-YY");
        });
        this.items = res.data.results;
      });
      this.isLoading = false;
    },
    gotoDelete(id, full_name) {
      this.openAssigneeDeleteModal = true;
      this.assignee_id = id;
      this.assignee_name = full_name;
    },
    deleteAssignee: function () {
      let data = {
        user_id: this.assignee_id,
        group_id: this.asset_group_id,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/group/remove-assigned-group/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.message) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.openAssigneeDeleteModal = false;
          this.load();
        }
      });
    },
  },
  // reference: https://renatello.com/vue-js-polling-using-setinterval/
};
</script>

<style scoped>
::v-deep .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}
</style>
